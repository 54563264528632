import ws_logo from "./images/ws-logo.svg";
import ws_logo_bg_white from "./images/ws_logo_bg_white.png";
import facebook_full_logo from "./images/facebook_full_logo.png";
import instagram_full_logo from "./images/instagram_full_logo.png";
import tiktok_full_logo from "./images/tik-tok_full_logo.png";
import phone_call_logo from "./images/phoneCall.svg";

const contactInfo = {
  website: 'https://wsagency-dz.com',
  tiktok: 'https://www.tiktok.com/@ws.agency',
  facebook: 'https://www.facebook.com/WS.AgencyDz3',
  instagram: 'https://www.instagram.com/wsagencydz'
}

function App() {
  return (
    <div class="flex flex-col items-center justify-between gap-10 my-5">
      <div class="flex flex-col items-center">
        <img
          className="w-64 h-40 " src={ws_logo} alt="ws-logo" />

        <div className="px-8 text-black text-xl text-center font-bold capitalize">
          The Solution to All Your  Digital Problems
        </div >
      </div>

      <div className="flex flex-col items-center justify-between gap-1">

        <div className="text-black text-xl font-semibold font-kanit lowercase">
          contact@wsagency-dz.com
        </div>

        <div className="flex gap-2">
          <img className="w-6 h-6 justify-start items-start inline-flex" src={phone_call_logo} alt="phone_call_logo" />
          <div className="text-black text-md font-medium capitalize">
            <a href={`tel:0559155791`}>
              0559.15.57.91
            </a>
            <span className="px-1">
              /
            </span>
            <a href={`tel:0783007061`}>
              0783.00.70.61
            </a>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center justify-between gap-5">
        <div className="text-zinc-900 text-xl font-bold capitalize"> links</div>

        <div className="flex flex-col justify-start items-center gap-4 w-screen sm:w-96 px-8">
          <a
            href={contactInfo.website}
            className="flex items-center self-stretch h-16 p-2 hover:cursor-pointer bg-gray rounded-[100px]"
          >
            <img className="w-12 h-12 rounded-full" src={ws_logo_bg_white} alt="ws-logo" />
            <div className="grow mr-8 text-zinc text-base font-medium text-center">Website</div>
          </a>

          <a
            href={contactInfo.tiktok}
            className="flex items-center self-stretch h-16 p-2 hover:cursor-pointer bg-gray rounded-[100px]"
          >
            <img className="w-12 h-12 rounded-full" src={tiktok_full_logo} alt="tiktok_full_logo" />
            <div className="grow mr-8 text-zinc text-base font-medium text-center">Tiktok</div>
          </a>

          <a
            href={contactInfo.instagram}
            className="flex items-center self-stretch h-16 p-2 hover:cursor-pointer bg-gray rounded-[100px]"
          >
            <img className="w-12 h-12 rounded-full" src={instagram_full_logo} alt="instagram_full_logo" />
            <div className="grow mr-8 text-zinc text-base font-medium text-center">Instagram</div>
          </a>

          <a
            href={contactInfo.facebook}
            className="flex items-center self-stretch h-16 p-2 hover:cursor-pointer bg-gray rounded-[100px]"
          >
            <img className="w-12 h-12 rounded-full" src={facebook_full_logo} alt="facebook_full_logo" />
            <div className="grow mr-8 text-zinc text-base font-medium text-center">Facebook</div>
          </a>
        </div>
      </div>

    </div >
  );
}

export default App;
